export default {
    //智能客服-会话列表
    GET_TALK_LIST: '/api/admin/aisbot/talk/list',
    //智能客服-会话消息列表
    GET_AISBOT_MESSAGE_LIST: '/api/admin/aisbot/message/list',
    //智能客服-发送会话消息
    SEND_AISBOT_MESSAGE: '/api/admin/aisbot/message/send',
    //分析报表-客服分析
    GET_AISBOT_REPORT: '/api/admin/aisbot/report/list',

    //智能客服-媒体账户列表 
    GET_MEMBER_LIST: '/api/admin/aisbot/member/list',
    //智能客服-绑定接待人
    SET_MEMBER_BIND: '/api/admin/aisbot/member/bind',
    //智能客服-解绑接待人
    SET_MEMBER_UNBIND: '/api/admin/aisbot/member/unbind',
    //智能客服-接待人分级列表
    GET_AISBOT_TALK_SERVICE: '/api/admin/aisbot/talk/service/select',

    //智能客服-C端客户详情
    GET_AISBOT_USER_INFO: '/api/admin/aisbot/user/info',
    //智能客服-C端客户编辑
    UPDATE_AISBOT_USER_INFO: '/api/admin/aisbot/user/update',

    //智能客服-固定标签列表
    GET_AISBOT_TAGS_LIST: '/api/admin/aisbot/tags/list',
    //智能客服-固定标签添加
    CREATE_AISBOT_TAG: '/api/admin/aisbot/tags/create',
    //智能客服-固定标签编辑
    UPDATE_AISBOT_TAG: '/api/admin/aisbot/tags/update',
    //智能客服-固定标签删除
    DELETE_AISBOT_TAG: '/api/admin/aisbot/tags/delete',

    //企微会话内容存档-会话列表
    GET_MSGAUDIT_TALK_LIST: '/api/admin/msgaudit/talk/list',
    //企微会话内容存档-消息列表
    GET_MSGAUDIT_MESSAGE_LIST: '/api/admin/msgaudit/message/list',
}
  