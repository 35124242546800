
//本地测试
let isPRO = false;
const dev_apiUrl = '//pvmaker.caridcc.com';
// const dev_apiUrl = '//ai.new4s.com';
let pro_apiUrl = null;
console.log(
    "%c车巡 %cAI %cVersion 1.0.5",
    "font-family: system-ui;font-style: italic; font-size: 36px; color: #6ecfc4; font-weight: bold; padding: 5px 0 10px 0;text-shadow: 0 2px 2px #488e83;vertical-align: baseline;",
    "font-family: system-ui;font-style: italic; font-size: 36px; color: #6ecfc4; font-weight: bold;padding: 0 10px 0 0;text-shadow: 0 2px 2px #488e83;vertical-align: baseline;",
    "color: #ffffff; font-style: italic; background-color: #002FA7;padding: 2px 6px;border-radius: 4px;",
    location.protocol+'//'+location.host
);
// console.log(location.protocol+'//'+location.host);
if(location.host === 'pvmaker.new4s.com' || location.host === 'ai.new4s.com'){
    isPRO = true;
    pro_apiUrl = '//'+location.host;
}

export default {
  tokenKey: 'Admin-Token',
  copyright: {
    text: 'Copyright ©2023-2024 车巡AI版权所有',
    url: ''
  },
  /**
   * 阿里云OSS
   */
  aliossConfig: {
    accessKeyId: 'LTAI4FocgSbYqAML37efnNy5',
    accessKeySecret: 'u79UvP86Hss3J1N9WtIoJH5CgBUT9I',
    bucket: 'cheyun-new4s-web',
    endpath: 'material/',
  },
  wssUrl: isPRO? pro_apiUrl:dev_apiUrl,
  apiUrl: location.protocol+(isPRO? pro_apiUrl:dev_apiUrl),
  meituAccessKey: isPRO?'SUvJhl1sjNK204UPseE7FKE1imvCggiu':'bXqD5pHnRT8aOSQvKRoGEpYlIohdYhMv',
}
