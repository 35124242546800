const user = {
    state: {
        userAuth: null,
    },
    mutations: {
        SET_USER_AUTH: (state, auth) => {
            state.userAuth = auth
        }
    },
    actions: {
        setUserAuth({commit}, auth) {
            commit('SET_USER_AUTH', auth);
        }
    }

};
export default user;
