import cookie from '@/lib/cookie';
import request from "@/lib/request";
import api from "@/api";
import _ from 'lodash';


//判断是否是在客户端环境
// target传win/mac 判断是否是在windows/macos环境
// 不传target则判断是否是客户端环境

export const isClient = (target) => {
  let ua = navigator.userAgent.toLocaleLowerCase();
  let result = false;
  if (target === 'win') {
    result = ua.indexOf('pvmaker-device-windows') >= 0;
  } else if (target === 'mac') {
    result = ua.indexOf('pvmaker-device-mac') >= 0;
  } else {
    result = ua.indexOf('pvmaker-device-windows') >= 0 || ua.indexOf('pvmaker-device-mac') >= 0;
  }
  return result;
};

// export const isClient = () => true;


function sleep(times = 100) {
  return new Promise(resolve => {
    setTimeout(resolve, times);
  });
}

// 等待获取到 new-auth
// 授权后添加cookie'new-auth' 成功为1,失败为0,检测到new-auth后,js要吧它移除或改为-1
let newAuthWaiting = false; //标识 new-auth 是否正在等待中
// window.__cookie = cookie;
export const waitNewAuth = async () => {
  const KEY = 'new-auth';
  cookie.remove(KEY);
  newAuthWaiting = true;
  const getNewAuth = () => {
    let res = cookie.get(KEY);
    res === undefined && (res = '');
    res = String(res);
    return res;
  };
  let newAuth = getNewAuth();
  // console.log('newAuth', newAuth);
  while (!newAuth) {
    if (newAuthWaiting) {
      await sleep(200);
      newAuth = getNewAuth();
    } else {
      break;
    }
    // console.log('newAuth', newAuth);
  }
  newAuthWaiting = false;
  return newAuth;
};
// 取消等待new-auth
export const cancelWaitNewAuth = async () => {
  newAuthWaiting = false;
  await sleep(200);
};

//
export const openUrl = (url) => {
  try {
    if (isClient()) { //运行在客户端
      //打开浏览器
      window.bridge.open_browser(url);
    } else {
      window.open(url, '_blank');
    }
  } catch (err) {
    console.error(err);
  }
};

export const openVideo = (url, callback) => {
  try {
    // console.log('openVideo', url);
    if (isClient()) {
      window.bridge.open_player(url);
    } else if (typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    console.error(err);
  }
};

//
export const getDeviceVersionInfo = async () => {
  const { res } = await request.post(api.public.GET_DEVICE_VERSION_INFO, {});
  let data = _.get(res, 'data');
  data = Array.isArray(data) ? data : [];
  return data;
};
