import api from '@/api';
import Alioss from 'ali-oss';
import config from '@/config';
import request from '@/lib/request';
import moment from 'moment';
import _ from 'lodash';

const { aliossConfig } = config;


export const materialUpload = async (postData) => {
  const { res } = await request.post(api.public.UPLOAD, postData);
  return res;
};

export const ossUploadFile = async (data, options = {}) => {
  const client = new Alioss({
    region: 'oss-cn-hangzhou',
    secure: true,
    accessKeyId: aliossConfig.accessKeyId,
    accessKeySecret: aliossConfig.accessKeySecret,
    bucket: aliossConfig.bucket,
  });
  let type = data.file.type.split('/')[0];
  const formData = new FormData();
  formData.append('type', type);
  formData.append('file', data.file, data.file.name);
  //
  const onProgress = options.onProgress || _.noop;
  const onSuccess = options.onSuccess || _.noop;
  const onError = options.onError || _.noop;
  const onComplete = options.onComplete || _.noop;
  //
  const multipartUpload = async () => {
    try {
      const name = data.file.name;
      const ext = ('.' + name.split('.').pop()) || '';
      const size = data.file.size;
      const rd = Math.floor(Math.random() * 89999 + 10000);
      const obj = aliossConfig.endpath + moment(Date.now()).format('YYYY-MM-DD') + '/' + moment(Date.now()).format('YYYYMMDDHHmmss') + '' + rd + ext;
      const result = await client.multipartUpload(obj, data.file, {
        progress: onProgress,
        parallel: 10,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 500 * 1024,
      });
      const status = _.get(result, 'res.status');
      if (status === 200) {
        const postData = {};
        postData.name = name;
        postData.path = result.name;
        postData.type = type;
        postData.ext = ext.substring(1).toLocaleLowerCase();
        postData.size = size;
        //
        // archive
        if (['rar', 'zip', '7z'].includes(postData.ext)) {
          postData.type = 'archive';
        }
        //
        const res = await materialUpload(postData);
        const status = _.get(res, 'status');
        const msg = _.get(res, 'msg');
        if (status === 'y') {
          let nres = res;
          nres.fileuid = data.file.uid || "";
          onSuccess(nres);
        } else if (msg) {
          onError(msg);
        }
      } else {
        onError('上传失败');
      }
      onComplete();
    } catch (e) {
      console.error(e);
      if (e.code === 'ConnectionTimeoutError') {
        onError('上传超时');
      } else {
        onError('上传失败');
      }
    }
  };
  multipartUpload();
};
