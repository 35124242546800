<template>
  <el-form class="pv-list-search-form">
    <div style="display: flex;justify-content: flex-start;align-items: flex-start;">
        <div class="form-wrap" :class="formWrapClass" :style="toggleShowAllEnable?'flex:1;':''">
            <div class="form-box">
                <slot></slot>
            </div>
        </div>
        <div class="ctrl-wrap">
            <slot name="ctrl"></slot>
        </div>
    </div>
    <div class="toggle-all" v-if="toggleShowAllEnable">
      <div class="link" v-if="showAll" @click="toggleShowAll">
        <img class="ic-tool" src="@/assets/images/home/tools/ic_arrow_shouqi.png" />
      </div>
      <div class="link" v-else @click="toggleShowAll">
        <img class="ic-tool" src="@/assets/images/home/tools/ic_arrow_zhankai.png" />
      </div>
      <!-- <el-link class="link" v-if="showAll" @click="toggleShowAll">收起字段</el-link>
      <el-link class="link" v-else @click="toggleShowAll">展开字段</el-link> -->
    </div>
    <slot name="append"></slot>
  </el-form>
</template>

<script>
export default {
  props: {
    defaultShowAll: { type: Boolean, default: true },
    defaultToggleShowAllEnable: { type: Boolean, default: false },
  },
  data() {
    return {
      toggleShowAllEnable: true,
      showAll: false
    };
  },
  computed: {
    formWrapClass() {
      if (this.showAll) {
        return 'show-all';
      }
      return '';
    }
  },
  methods: {
    getPvList() {
      let pvList = this.$parent;
      while (pvList.$options.name !== 'pvList') {
        pvList = pvList.$parent;
      }
      return pvList;
    },
    handleSearch(formData) {
      const pvList = this.getPvList();
      if (pvList) {
        pvList.handleSearch(formData);
      }
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
      //切换搜索条件的显示后table重新计算高度
      this.$emit('toggle', this.showAll);
    },
    styleSync() {
      const formWrap = this.$el.querySelector('.form-wrap');
      const boxWrap = this.$el.querySelector('.form-wrap .form-box');
      const wrapHeight = formWrap.offsetHeight;
      const boxHeight = boxWrap.offsetHeight;
      if (wrapHeight >= boxHeight) {
        this.showAll = true;
        this.toggleShowAllEnable = false;
      }
    }
  },
  created() {
    this.showAll = !!this.defaultShowAll;
    this.toggleShowAllEnable = !!this.defaultToggleShowAllEnable;
  },
  mounted() {
    this.styleSync();
  }
}
</script>

<style lang="scss" scoped>
.pv-list-search-form {
  padding: 15px;
  text-align: left;
  background-color: #ffffff;

  :deep {
    .el-form-item,
    .el-form-item__label,
    .el-form-item__content {
      display: inline-block;
      vertical-align: top;
      white-space: nowrap;
    }

    .el-form-item__label {
      padding-left: 10px;
      font-size: 12px;
      color: #222222;
    }

    .el-form-item {
      margin-bottom: 0;
      margin-right: 15px;
    }

    .el-button--mini {
      margin-top: 7px;
    }

    .el-input__inner {
        border-radius: 3px 3px 3px 3px;
    }

  }
}

.ctrl-wrap {
  text-align: right;
  white-space: nowrap;
}

.toggle-all {
  text-align: center;
  margin-top: 10px;

  .link {
    font-size: 12px;
    display: block;
    margin: -15px auto;
    width: 50px;
    padding: 10px 0;
    cursor: pointer;
  }
}

.form-wrap {
//   flex: 1;
  height: 41px;
  overflow: hidden;

  &.show-all {
    height: auto;
  }
}
</style>
